import React from 'react'
import {
  CFView,
  CFText,
  CFImage,
  CFLink,
  DefaultScreen,
  MobileScreen,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import DownloadAppButton from './DownloadAppButton'
import { hero, heroText, mobileHero, mobileHeroText } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView
          h="95VH"
          maxHeight="700px"
          w="100%"
          image={`url(${mobileHero}) top / cover no-repeat`}
          boxShadow="0 2px 2px rgba(0,0,0,.5)"
          column
          justifyBetween
          alignCenter
        >
          <Header />
          <CFView
            textCenter
            column
            justifyEnd
            alignCenter
            w="100%"
            h="100%"
            mb="4vh"
          >
            <CFView pulsate>
              <OrderPickupButton />
            </CFView>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          h="100vh"
          maxHeight="700px"
          w="100%"
          image={`url(${hero}) center / cover no-repeat`}
          zIndex={90}
          column
          justifyBetween
          boxShadow="0 2px 2px rgba(0,0,0,.5)"
        >
          <Header />
          <CFView w="100%" row center bg="black" h="60px">
            <CFView style={{ fontSize: 28 }} xBold raleway white mr="15px">
              NOW TAKING ONLINE ORDERS
            </CFView>
            <CFView mt="10px">
              <OrderPickupButton />
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
